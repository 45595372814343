<template>
    <component :is="'div'">
  
      <template>
        <b-row
          class="content-header"
        >
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="9"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h2 class="float-left mb-0">
                  Імпорт водіїв
                </h2>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
  
      <b-card>
        
        <b-form
            class="p-2"
            @submit.prevent="submitFile()"
        >
          <!--  -->
  
            <div class="mb-1">
                <h4 class="mb-0">
                    Імпорт водіїв
                </h4>
            </div>

            <b-form-file
                placeholder="Виберіть файл або перетягніть його сюди..."
                drop-placeholder="Перетягніть файл сюди..."
                v-model="file"
                ref="file"
                id="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                @change="handleFileUpload()"
            />

            <!-- Form Actions -->
            <div class="d-flex align-items-center mt-2">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
              >
                  Надіслати
              </b-button>
              <small class="text-success" v-if="saved">Водіїв імпортовано</small>

            </div>

        </b-form> 
      </b-card>     
  
    </component>
  </template>
  
<script>
import {
  BCard, BForm, BButton, BFormFile, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axiosIns from "@/libs/axios";
  
  export default {
    components: {
        BCard, 
        BForm, 
        BButton,
        BFormFile,
        BRow, 
        BCol,
    },
    directives: {
      Ripple,
    },

    data() {
        return {
            file: null,
            saved: false,
        }
    },
    methods: {
      submitFile() {

        let formData = new FormData();

        formData.append('import', this.file);
        
        axiosIns.post("/business/import/drivers", formData).then(response => {
          this.saved = !this.saved
        })
        .catch(error => {
          this.saved = !this.saved
        });
      },
      handleFileUpload(){
        this.file = this.$refs.file.files[0];
      }
    }
  }
  </script>
  
  <style>
  
  </style>
  